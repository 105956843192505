<template>
  <div 
    class='default-layout-header'>
    <nav class='layout-container'>
      <div class='flex-grow-0 flex-shrink-0 flex flex-row justify-start items-center' style='max-width:50%;'>
        <img 
          :src='eventConfigLogoImageUrl'
          class='block h-10'
          :style='eventConfigLogoImageCssStyle'/>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DemoLayoutPublicHeader',
  computed: {
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
      'eventConfigLogoImageCssStyle',
    ]),
  },
}
</script>
